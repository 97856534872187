import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "./img/logo.png";
import { ExternalLink } from "react-external-link";

var list = {
  listStyleType: `none`,
};

var list_heading = {
  fontSize: `1.05em`,
  textTransform: `uppercase`,
  color: `#0984e3`,
  fontWeight: `600`,
};

var list_item = {
  fontSize: `1em`,
  fontWeight: `400`,
  lineHeight: `2em`,
  color: `#000`,
  cursor: `pointer`,
  textDecoration: `none`,
};

var about_footer = {
  margin: `0`,
  padding: `0`,
  backgroundColor: `#0d0f36`,
  color: `#fff`,
};

var about = {
  fontSize: `1.1em`,
  fontWeight: `500`,
  color: `#fff`,
};

var copyright = {
  fontSize: `1.1em`,
  fontWeight: `500`,
  textTransform: `uppercase`,
  color: `#fff`,
};

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Container fluid className="mt-4 mb-3 ps-3 pe-3">
          <Row>
            <Col xs={12} lg={3}>
              <ul style={list}>
                <li>
                  <img
                    src={logo}
                    alt="datareveal"
                    className="img-fluid mb-3 d-sm-none d-md-none d-lg-block"
                    height="100%"
                    width="50%"
                  />
                </li>
                <li>
                  <img
                    src={logo}
                    alt="datareveal"
                    className="img-fluid mb-3 d-none d-sm-block d-md-block d-lg-none"
                    height="100%"
                    width="25%"
                  />
                </li>
                <li>
                  <address>
                    5050 Quorum Drive, Suite 700, Dallas, TX 75254, USA
                  </address>
                </li>
                <li>
                  <address>
                    Suite 207, 4430 Bathurst Street, North York, Toronto, ON
                    M3H3S3, Canada
                  </address>
                </li>
                <li>
                  <address>
                    No 102, Bazulla Road, T Nagar, Chennai-600017, India
                  </address>
                </li>
                <li>
                  <ExternalLink
                    href="mailto:info@datareveal.ai"
                    style={list_item}>
                    info@datareveal.ai
                  </ExternalLink>
                </li>
                <li style={list_item}>USA - +1 704 206 9793</li>
                <li style={list_item}>CA - +1 647 781 5080</li>
                <li style={list_item}>IN - +91 994 013 1888</li>
              </ul>
            </Col>
            <Col xs={12} lg={2}>
              <ul style={list}>
                <li>
                  <h5 style={list_heading}>company</h5>
                </li>
                <li>
                  <Link to="/" style={list_item}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" style={list_item}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" style={list_item}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={3}>
              <ul style={list}>
                <li>
                  <h5 style={list_heading}>extract platform</h5>
                </li>
                <li>
                  <Link to="/api" style={list_item}>
                    API Development
                  </Link>
                </li>
                <li>
                  <Link to="/language" style={list_item}>
                    Language
                  </Link>
                </li>
                <li>
                  <Link to="/learning" style={list_item}>
                    Learning
                  </Link>
                </li>
                <li>
                  <Link to="/product" style={list_item}>
                    Product Development
                  </Link>
                  <li>
                    <Link to="/vision" style={list_item}>
                      Vision
                    </Link>
                  </li>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={2}>
              <ul style={list}>
                <li>
                  <h5 style={list_heading}>industries</h5>
                </li>
                <li>
                  <Link to="/bank" style={list_item}>
                    Banking
                  </Link>
                </li>
                <li>
                  <Link to="/crypto" style={list_item}>
                    Crypto&Blockchain
                  </Link>
                </li>
                <li>
                  <Link to="/cybersecurity" style={list_item}>
                    Cybersecurity
                  </Link>
                </li>
                <li>
                  <Link to="/data" style={list_item}>
                    Data
                  </Link>
                </li>
                <li>
                  <Link to="/healthcare" style={list_item}>
                    Healthcare
                  </Link>
                </li>
                <li>
                  <Link to="/insurance" style={list_item}>
                    Insurance
                  </Link>
                </li>
                <li>
                  <Link to="/legal" style={list_item}>
                    Legal
                  </Link>
                </li>
                <li>
                  <Link to="/oil" style={list_item}>
                    Oil Usecases
                  </Link>
                </li>
                <li>
                  <Link to="/city" style={list_item}>
                    Smart Cities
                  </Link>
                </li>
                <li>
                  <Link to="/transport" style={list_item}>
                    Transportation
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={2}>
              <ul style={list}>
                <li>
                  <h5 style={list_heading}>products</h5>
                </li>
                <li>
                  <Link to="/genomics" style={list_item}>
                    GENOMICS DNA API
                  </Link>
                </li>
                <li>
                  <Link to="/fhir" style={list_item}>
                    FL7-FHIR API
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Row className="p-2" style={about_footer}>
          <Col lg={6}>
            <ExternalLink href="http://www.vebbox.com/" style={list_item}>
              <p className="text-start" style={about}>
                Developed By Vebbox Software Solutions
              </p>
            </ExternalLink>
          </Col>
          <Col lg={6}>
            <p className="text-end" style={copyright}>
              All right Reserved ©Datareveal AI
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}
