import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Parallax from "../component/parallax";
import Getintouch from "../component/get-in-touch";
import Footer from "../component/footer";
import Heading from "../component/heading";

import content_one from "../component/img/industries/bank/bank-content-one.jpg";
import content_two from "../component/img/industries/bank/bank-content-two.jpg";
import content_three from "../component/img/industries/bank/bank-content-three.jpg";
import content_four from "../component/img/industries/bank/bank-content-four.jpg";

import subcontent_one from "../component/img/industries/bank/subcontent-one.jpg";

function Bank() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="mt-5 mt-sm-0 mt-md-0 mt-lg-0">
      <Row className="pt-5" id="bank-landing" style={{ margin: 0, padding: 0 }}>
        <Col lg={1}></Col>
        <Col className="mx-auto my-auto">
          <h6 id="fhir-icon">
            <span class="material-icons">account_balance</span>
          </h6>
          <span className="text-white" id="fhir-heading">
            Datareveal - BANK
          </span>
          <br />
          <span className="text-white" id="fhir-p">
            AI-bank of the future
          </span>
          <h2 className="text-white mt-2" id="fhir-sub-heading">
            Artificial intelligence technologies are increasingly integral to
            the world we live in, and banks need to deploy these technologies at
            scale to remain relevant. Success requires a holistic transformation
            spanning multiple layers of the organization.
          </h2>
        </Col>
        <Col lg={1}></Col>
      </Row>

      {/* 1 */}
      <Row className="mt-3" style={{ margin: 0, padding: 0 }}>
        <Col lg={12} md={12} xs={12} sm={12}>
          <Heading
            title="LET DR-BUILDING THE AI BANK OF THE FUTURE"
            sub="Your people have better things to do."
          />
        </Col>
      </Row>

      <Container>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="p-3"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <div id="content-img-inner">
                <img
                  src={subcontent_one}
                  alt="transportation datareveal"
                  className="img-fluid"
                />
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">Why must banks become AI-first?</h2>
            <p id="content_p">
              Over several decades, banks have continually adapted the latest
              technology innovations to redefine how customers interact with
              them. The 2000s saw broad adoption of 24/7 online banking,
              followed by the spread of mobile-based “banking on the go” in the
              2010s.
            </p>
            <p id="content_p">
              Few would disagree that we’re now in the AI-powered digital age,
              facilitated by falling costs for data storage and processing,
              increasing access and connectivity for all, and rapid advances in
              AI technologies. These technologies can lead to higher automation
              and, when deployed after controlling for risks, can often improve
              upon human decision making in terms of both speed and accuracy.
            </p>
          </Col>
        </Row>
      </Container>

      <Row
        className="text-center p-4"
        style={{ margin: 0, padding: 0 }}
        id="product-page-content">
        <h5 className="mb-3">
          DATAREVEAL - To thrive in the AI-powered digital age, banks will need
          an AI-and-analytics capability stack that delivers intelligent,
          personalized solutions and distinctive experiences at scale in real
          time.
        </h5>
        <img
          src={content_one}
          alt="data datareveal"
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1200"
        />
      </Row>

      {/* 2 */}
      <Row className="mt-2" style={{ margin: 0, padding: 0 }}>
        <Parallax parallax_content="How Can AI Modernize The Banking Industry?" />
      </Row>

      <Container>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="p-3"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <img
                src={content_two}
                alt="transportation datareveal"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">AI Enhances Customer experience</h2>
            <p id="content_p">
              AI banking apps can do wonders. AI mobile banking apps for
              Android/iOS are aimed to improve customer experiences and service
              quality. Implementation of AI and Machine Learning in banking help
              companies in tracking user behavior and delivering highly
              personalized services to customers.
            </p>
            <p id="content_p">
              Hence, 70% of the banks are looking ahead to integrating AI in
              mobile banking apps and stepping forward to embrace the golden
              opportunities of AI in banking industry.
            </p>
          </Col>
        </Row>
        <hr class="progress" />
        <Row>
          <Col
            lg={{ span: 6, order: 0 }}
            md={{ span: 12, order: 1 }}
            sm={12}
            xs={{ span: 12, order: 1 }}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">AI For Risk Management</h2>
            <p id="content_p">
              It is one of the significant advantages of AI-enabled smart
              banking services. For instance, checking financial status,
              document verification, and releasing loans are risk-related
              activities for bankers. The use of AI and machine learning in
              banking can tackle this intelligently.
            </p>
            <p id="content_p">
              AI and machine learning in banking can do this task with more
              accuracy and privacy. AI-based mobile banking applications easily
              financial activities and analyze the banking data of the borrower.
              It could help bankers to identify the risks in giving loans to
              them. In addition, using the AI-driven risk assessment process,
              bankers can analyze the borrower’s behavior and thus can reduce
              the possibility of fraudulent acts.
            </p>
          </Col>
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 0 }}
            sm={12}
            xs={{ span: 12, order: 0 }}
            className="p-3"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <img
                src={content_three}
                alt="transportation datareveal"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>

      {/* 3 */}
      <Row className="p-4" style={{ margin: 0, padding: 0 }}>
        <p className="mb-3" style={{ color: `#0984e3` }}>
          AI and Machine Learning In Finance
        </p>
        <h5 className="mb-3">
          The finance industry, including the banks, trading, and fintech firms,
          are rapidly deploying machine algorithms to automate time-consuming,
          mundane processes, and offering a far more streamlined and
          personalized customer experience.{" "}
        </h5>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="p-3"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <img
                src={content_four}
                alt="transportation datareveal"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">
              Future Prospects of Machine Learning In Finance
            </h2>
            <p id="content_p">
              While some of the applications of machine learning in banking &
              finance are clearly known and visible such as chatbots and mobile
              banking apps, the ML algorithms and technology are now being
              gradually used for innovative future applications as well, by
              drawing out historical data of customers accurately and predicting
              their future.
            </p>
            <p id="content_p">
              Apart from the established use cases of machine learning in
              finance, as discussed in the above section, there are several
              other promising applications that ML technology can offer in the
              future. While few of these have relatively active applications
              today, others are still at a nascent stage.
            </p>
          </Col>
        </Row>
      </Row>

      <Getintouch />

      <Footer />
    </div>
  );
}

export default Bank;
