import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
// import landing_img from '../component/img/fhir/fhir-landing.gif'
import Content from '../component/content'
import Footer from '../component/footer'
import Getintouch from '../component/get-in-touch'

import content_one from '../component/img/fhir/hl7-content-two.PNG'
import content_two from '../component/img/fhir/hl7-content-three.PNG'
import content_three from '../component/img/fhir/content-three.jpg'

import product_page_content_one from '../component/img/fhir/hl7-content-one.PNG'
import Pagecontent from '../component/page-content'

import gen_vision_one from '../component/img/fhir/serv-one.png'
import gen_vision_two from '../component/img/fhir/serv-two.png'
import gen_vision_three from '../component/img/fhir/serv-three.png'

export default class Fhir extends Component {
    render() {
        return (
            <div>
                <Row className="pt-5" id="fhir-landing" style={{ margin: 0, padding: 0 }}>
                    <Col lg={1}></Col>
                    <Col className="mx-auto my-auto">
                        <h6 id="fhir-icon"><span class="material-icons">
                            api
                        </span></h6>
                        <span className="text-white" id="fhir-heading">Datareveal - FHIR</span><br />
                        <span className="text-white" id="fhir-p">Agility at Scale</span>
                        <h2 className="text-white mt-2" id="fhir-sub-heading">A platform that will help healthcare providers to reduce complexity of the system integrations and connect different EHR systems.</h2>
                    </Col>
                    <Col lg={1}></Col>
                </Row>

                <Row className="text-center p-5" style={{ margin: 0, padding: 0 }}>
                    <h4 className="mb-2">DR - Fast Healthcare Interoperability Resources</h4>
                    <p>Fast Healthcare Interoperability Resources is a standard describing data formats and elements and an application programming interface for exchanging electronic health records. The standard was created by the Health Level Seven International health-care standards organization.</p>
                    <Pagecontent
                        general_page_one="Agile external environment
                        External Integration Environment"
                        general_page_two="External Test Environment
                        Pre-packaging of sets of services"
                        general_page_three="Aggregating layer for data from multiple sources
                        Temporary Communications tool"

                        page_content_img_one={gen_vision_one} page_content_img_alt_one="vision datareveal"
                        page_content_img_two={gen_vision_two} page_content_img_alt_two="vision datareveal"
                        page_content_img_three={gen_vision_three} page_content_img_alt_three="vision datareveal"
                    />
                </Row>


                <Row className="text-center p-4" style={{ margin: 0, padding: 0 }} id="product-page-content">
                    <Container>
                        <p className="mb-3">THE PLATFORM</p>
                        <h2 className="mb-3">DATAREVEAL - FHIR</h2>
                        <img src={product_page_content_one} alt="product datareveal" className="img-fluid" />
                    </Container>
                </Row>

                <Content
                    content_img_one={content_one} content_img_one_alt="fhir datareveal"
                    content_img_two={content_two} content_img_two_alt="fhir datareveal"
                    content_img_three={content_three} content_img_three_alt="fhir datareveal"

                    vision_head_one="HL-7 HEALTHCARE INFORMATION"
                    vision_head_two="HAPI DR-FHIR SERVER"
                    vision_head_three="DR-FHIR SPECIFICATION"

                    vision_content_one="Health Level 7’s (HL7’s) new standard, DR - FHIR (Fast Health Interoperability Resources), is setting healthcare information technology and medical imaging specifically ablaze with excitement. This aims to describe the protocol’s advantages in some detail and explore an easy path for those unfamiliar with FHIR to begin learning the standard using free, open-source tools, namely the HL7 application programming interface (HAPI) FHIR server and the SIIM Hackathon Dataset."

                    vision_content_two="HL7 application programming interface (HAPI, pronouncedBhappy ) has long existed as a go-to library for incorporationof HL7 v2 into applications written in Java. The HAPIcommunity has focused on creation of a FHIR libraryallowing for both consuming and exposing FHIR APIs. Theproject is feature rich including: the server, excellent docu-mentation, a supportive community, and frequent releases(fixing bugs, adding features, and expanding support fornewer FHIR resources and standards)."

                    vision_content_three="The building block in FHIR is a Resource,which provides a com-mon way to define and represent all exchangeable content and re-lated metadata in a particular modeling domain. In this study, weleveraged both document resources Composition/Bundle and clinicalresources Condition, Procedure, MedicationStatement/Medication,and FamilyMemberHistory to model unstructured EHR data andNLP outputs."
                />

                <Getintouch />

                <Footer />
            </div >
        )
    }
}
