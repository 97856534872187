import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Getintouch from "./get-in-touch";
import Footer from "./footer";

function Teambio(props) {
  return (
    <>
      <div style={{ backgroundColor: `rgb(249, 249, 249)` }}>
        <Container>
          <Row>
            <Col xxl xl lg md sm className="mt-3 mb-2">
              <h3>{props.post}</h3>
            </Col>
          </Row>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={4}>
              <img
                src={props.person}
                alt="team datareveal"
                className="ps-sm-3 ps-lg-0 ps-md-0 img-fluid"
              />
            </Col>
          </Row>
          <Row>
            <Col
              xxl
              xl
              lg
              md
              sm
              className=""
              style={{
                lineHeight: "2em",
                textAlign: `justify`,
                fontSize: `1.1em`,
              }}>
              <h3 className="mt-3">{props.name}</h3>
              <h5>{props.role}</h5>
              <p>{props.details}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Getintouch />
      <Footer />
    </>
  );
}

export default Teambio;
