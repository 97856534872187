import React from "react";
import { useEffect } from "react";
import Teambio from "../Teambio";
function Teams(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-5 pt-4">
      <Teambio {...props} />
    </div>
  );
}

export default Teams;
