import React, { Component } from 'react'
import Content from '../component/content'
import Footer from '../component/footer'
import Getintouch from '../component/get-in-touch'
import Landing from '../component/landing'
import Pagecontent from '../component/page-content'
import Parallax from '../component/parallax'

import landing_img from '../component/img/extract-platform/product/product-landing.png'

import gen_vision_one from '../component/img/extract-platform/product/product-serv-one.png'
import gen_vision_two from '../component/img/extract-platform/product/product-serv-two.png'
import gen_vision_three from '../component/img/extract-platform/product/product-serv-three.png'

import content_one from '../component/img/extract-platform/product/content-one.jpg'
import content_two from '../component/img/extract-platform/product/content-two.jpg'
import content_three from '../component/img/extract-platform/product/content-three.jpg'

export default class Productpage extends Component {
    render() {
        return (
            <div>
                <Landing
                    landing_img={landing_img} landing_img_alt="vision datareveal"
                    landing_h2="Datareveal Extract - Product Development"
                    landing_h3="Our product development, is a series of steps that includes the conceptualization, design, development and marketing of newly created or newly rebranded goods or services."
                />
                <Pagecontent
                    vision_content_h3="Product Development"
                    vision_content_h6="Datareveal"
                    vision_page_content_p="Our product development, is a series of steps that includes the conceptualization, design, development and marketing of newly created or newly rebranded goods or services."

                    general_page_one="A large part of the development of any product is the strategy. We collaborate from the start to identify opportunities and develop concepts, helping to shape your business strategy. We partner with you to identify the right-sized delivery strategy."
                    general_page_two="We manage the engagement, execute against the plan, build what’s needed, and remain laser focused on your goal. We integrate our teams with yours in an agile methodology, working together with you through the sprint planning, testing, release, and retro."
                    general_page_three="Your product is unique, and we know that. We determine the right strategy for you and release. We’ll address all your needs around migration, training, and change management. We directly address issues and feedback, enhancing happy."

                    page_content_img_one={gen_vision_one} page_content_img_alt_one="vision datareveal"
                    page_content_img_two={gen_vision_two} page_content_img_alt_two="vision datareveal"
                    page_content_img_three={gen_vision_three} page_content_img_alt_three="vision datareveal"
                />
                <Parallax
                    parallax_content="Our teams of experienced product development experts are ready to partner closely with clients to quickly and successfully define, build, and evolve compelling, high-performing product solutions in a variety of shapes and sizes."
                />
                <Content
                    content_img_one={content_one} content_img_one_alt="vision datareveal"
                    content_img_two={content_two} content_img_two_alt="vision datareveal"
                    content_img_three={content_three} content_img_three_alt="vision datareveal"

                    vision_head_one="Amplify Your Value With Custom Product Development"
                    vision_head_two="Trust in Experienced and Accountable Teams"
                    vision_head_three="Execute Against a Common Vision"

                    vision_content_one="The world has changed, with digital presenting unprecedented opportunities for new revenue streams for companies across industries. It’s imperative that you act now, because if you don’t, your competitors will. What will set you apart is your use of technology as a force multiplier to amplify your unique value."

                    vision_content_two="Some firms have local build centers. Others have offshore development teams. With our US and nearshore product development colleagues, we can assemble cross-functional teams to collaborate at scale and develop products faster and more consistently than the competition.We most often work in one of two models, serving as the core team focused on the delivery goal while working closely with your teams, or working as a “pod” among a larger set of teams driving the work in a specific area of responsibility."

                    vision_content_three="Quick wins are often vital for your business. We scale up as direction is set and parallel work streams are possible to support your goal of getting value released as fast as possible. We have U.S. based consultants as well as nearshore and offshore colleagues and can start small but grow quickly to large team sizes with competitive cost structures."
                />
                <Getintouch />
                <Footer />
            </div>
        )
    }
}
