import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import routeConfig from "./../utils/routes";
import users from "./teammate/teamUsers";
import Teams from "./teammate";
import Navigationbar from "./navbar";

export default class Navigation extends Component {
  render() {
    const routes = routeConfig;
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div>
          <Navigationbar />
        </div>
        <div>
          <Routes>
            {/* general pages */}
            {routes.map((route) => {
              const { path = "/", Component, exact = true, ...rest } = route;
              return (
                <Route
                  path={path}
                  exact={exact}
                  element={<Component />}
                  {...rest}
                />
              );
            })}

            {/* team member pages */}
            {users.map((user) => {
              return (
                <Route
                  path={user.path}
                  exact={true}
                  element={<Teams {...user} />}
                />
              );
            })}
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}
