import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Content from "../component/content";
import Feature from "../component/features";
import Footer from "../component/footer";
import Getintouch from "../component/get-in-touch";

import content_one from "../component/img/genomics/genomics-content-two.jpg";
import content_two from "../component/img/genomics/genomics-content-three.jpg";
import content_three from "../component/img/genomics/genomics-content-four.jpg";

export default class Genomics extends Component {
  render() {
    return (
      <div className="mt-5 mt-sm-0 mt-md-0 mt-lg-0">
        <Row
          className="pt-5"
          id="genomics-landing"
          style={{ margin: 0, padding: 0 }}>
          <Col lg={1}></Col>
          <Col className="mx-auto my-auto">
            <h6 id="fhir-icon">
              <span class="fas fa-dna"></span>
            </h6>
            <span className="text-white" id="fhir-heading">
              Datareveal - GENOMICS
            </span>
            <br />
            <span className="text-white" id="fhir-p">
              GENOMICS DNA API
            </span>
            <h2 className="text-white mt-2" id="fhir-sub-heading">
              Genomic DNA from hela cells exposed to normoxia (N) or hypoxia (H)
              for 5 h or to staurosporine (St) for 24 hours was extracted by the
              Apoptotic DNA Ladder Isolation Kit and electrophorsesd in an
              agarose gel, which was stained with ethidium bromide.
            </h2>
          </Col>
          <Col lg={1}></Col>
        </Row>
        <Row className="text-center p-5" style={{ margin: 0, padding: 0 }}>
          <h4 className="mb-2">GENOMICS DNA API INTELLIGENT</h4>
          <p>
            The genome of an organism (encoded by the genomic DNA) is the
            (biological) information of heredity which is passed from one
            generation of organism to the next. That genome is transcribed to
            produce various RNAs, which are necessary for the function of the
            organism.
          </p>
        </Row>

        <Content
          content_img_one={content_one}
          content_img_one_alt="vision datareveal"
          content_img_two={content_two}
          content_img_two_alt="vision datareveal"
          content_img_three={content_three}
          content_img_three_alt="vision datareveal"
          vision_head_one="BUILDING BLOCK OF LIFE"
          vision_head_two="FUNCTION OF GENOMICS"
          vision_head_three="Complete genomes"
          vision_content_one="Deoxyribonucleic acid, (DNA) is the molecule that carries the instructions for all aspects of an organism’s functions, from growth, to metabolism, to reproduction. In living organisms, most of the DNA resides in tightly coiled structures called chromosomes, located inside the nucleus in each cell."
          vision_content_two="Genomic DNA, or gDNA, is the chromosomal DNA of an organism, representing the bulk of its genetic material. It is distinct from bacterial plasmid DNA, complementary DNA, or mitochondrial DNA. Genome sizes vary by organism, ranging from a few kilobases in viruses, a few megabases in bacteria, and tens to hundreds of gigabases in higher eukaryotes."
          vision_content_three="The advent of these technologies resulted in a rapid intensification in the scope and speed of completion of genome sequencing projects. The first complete genome sequence of a eukaryotic organelle, the human mitochondrion (16,568 bp, about 16.6 kb [kilobase]), was reported in 1981, and the first chloroplast genomes followed in 1986. The first eukaryotic chromosome, chromosome III of brewer's yeast Saccharomyces cerevisiae (315 kb) was sequenced."
        />

        <Feature />

        <Getintouch />

        <Footer />
      </div>
    );
  }
}
