import About from "../pages/about";
import Apipage from "../pages/api";
import Bank from "../pages/bank";
import City from "../pages/city";
import Contact from "../pages/contact";
import Crypto from "../pages/crypto";
import Cybersecurity from "../pages/cybersecurity";
import Data from "../pages/data";
import Fhir from "../pages/fhir";
import Genomics from "../pages/genomics";
import Healthcare from "../pages/healthcare";
import Insurance from "../pages/insurance";
import Language from "../pages/language";
import Learningpage from "../pages/learning";
import Legal from "../pages/legal";
import Oil from "../pages/oil";
import Productpage from "../pages/product";
import Transport from "../pages/transport";
import Visionpage from "../pages/vision";
import Home from "./../pages/home";

const routeConfig = [
  { Component: Home, path: "/" },
  { Component: Contact, path: "/contact" },
  { Component: About, path: "/about" },

  { Component: Visionpage, path: "/vision" },
  { Component: Learningpage, path: "/learning" },
  { Component: Language, path: "/language" },
  { Component: Apipage, path: "/api" },
  { Component: Productpage, path: "/product" },

  { Component: Cybersecurity, path: "/cybersecurity" },
  { Component: Data, path: "/data" },
  { Component: City, path: "/city" },
  { Component: Transport, path: "/transport" },
  { Component: Bank, path: "/bank" },
  { Component: Healthcare, path: "/healthcare" },
  { Component: Legal, path: "/legal" },
  { Component: Oil, path: "/oil" },
  { Component: Insurance, path: "/insurance" },
  { Component: Crypto, path: "/crypto" },

  { Component: Fhir, path: "/fhir" },
  { Component: Genomics, path: "/genomics" },
];

export default routeConfig;
