import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Content from '../component/content'
import Landing from '../component/landing'
import Parallax from '../component/parallax'
import Footer from '../component/footer'
import Getintouch from '../component/get-in-touch'

import landing_img from '../component/img/extract-platform/learning/learning-landing.png'

import content_one from '../component/img/extract-platform/learning/content-img-one.jpg'
import content_two from '../component/img/extract-platform/learning/content-img-two.jpg'
import content_three from '../component/img/extract-platform/learning/content-img-three.jpg'

export default class Learningpage extends Component {
    render() {
        return (
            <div>
                <Landing
                    landing_img={landing_img} landing_img_alt="learning datareveal"
                    landing_h2="Datareveal Extract - Learning"
                    landing_h3="Go beyond data extraction to automate insights and decisions, not just tasks."
                />

                <Container>
                    <Row className="mt-2">
                        <Col>
                            <h6 className="mb-2" id="page_content_h6">Datareveal</h6>
                            <h3 id="page_content_h3">Learning</h3>
                            <p id="page_content_p">Empower Vision and Language solutions with Extract Learning models to automate processes that ordinarily require human judgment, extract actionable insights from processed data, and make better data-driven decisions.</p>
                        </Col>
                    </Row>
                    <Row id="page_content_service">
                        <Col lg={6} md={6} className="p-2">
                            <p><i class="fas fa-magic"></i> &nbsp;Cognitive Reasoning Systems and Process Automation</p>
                        </Col>
                        <Col lg={6} md={6} className="p-2">
                            <p><i class="fas fa-hourglass"></i> &nbsp;Supervised and Unsupervised Classification and Clustering</p>
                        </Col>
                        <Col lg={6} md={6} className="p-2">
                            <p><i class="fas fa-history"></i> &nbsp;Predictive Modeling and Time-Series Forecasting</p>
                        </Col>
                        <Col lg={6} md={6} className="p-2">
                            <p><i class="fas fa-chart-line"></i> &nbsp;Prescriptive Analytics and Decision Optimization</p>
                        </Col>
                    </Row>
                </Container>

                <Parallax
                    parallax_content="Leverage data to extract actionable insights and make better data-driven decisions."
                />

                <Content
                    content_img_one={content_one} content_img_one_alt="learning datareveal"
                    content_img_two={content_two} content_img_two_alt="learning datareveal"
                    content_img_three={content_three} content_img_three_alt="learning datareveal"

                    vision_head_one="Intelligently Process Documents"
                    vision_head_two="Extract Insights from Dark Data"
                    vision_head_three="Forecast the future"

                    vision_content_one="Unlike traditional OCR applications that rely on templates and zonal extraction, Datareveal Extract leverages AI to read documents like a human, understanding the context, meaning and intent of language. Datareveal AI uses knowledge mapping to configure Extract to perform complicated processes, integrating both deterministic and probabilistic models to make judgement calls, and using confidence thresholds to determine when human involvement is necessary. Extract continuously learns from human feedback, getting faster and more accurate each day."

                    vision_content_two="Whether its automating manual processes, uncovering actionable insights, or predicting future events, digital transformation relies on structured data. But 90% of enterprise data is locked away in documents, images, and videos and the rate at which this unstructured data is being created is increasing exponentially. Not only can Datareveal AI create structure out of this unstructured data, its unsupervised clustering, supervised classification, and other Learning models can detect even subtle patterns and coax out meaningful insights. Go beyond simply processing data and use it to predict future events and prescribe the best course of action."

                    vision_content_three="Extract leverages transfer learning to configure its suite of deep learning and time-series forecasting models specifically for each use case. The Datareveal AI team has built and deployed in production hundreds of predictive models across industries, ranging from helping energy utilities forecast when machine component parts will fail to helping a large multi-national appliance maker model future customer demand. The Datareveal AI team has particular expertise in healthcare predictive models, having deployed over 100 different revenue, operational, and clinical predictive models for healthcare providers around the globe."
                />

                {/* <Slide /> */}
                <Getintouch />
                <Footer />
            </div>
        )
    }
}
