import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./component/navigation";

function App() {
  return (
    <div>
      <header>
        <Navigation />
      </header>
    </div>
  );
}

export default App;
