import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slide_one from "./img/client/Microsoft-Logo.png";
import slide_two from "./img/client/Amazon-Web-Services-Logo.png";
import slide_three from "./img/client/Google-Cloud-Platform-Logo.png";
import slide_four from "./img/client/outsystems-logo.svg";

export default class CarouselSlider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="container p-5">
        <h2 className="text-center text-uppercase text-secondary mb-5">
          Our Partners
        </h2>
        <Slider {...settings}>
          <div>
            <img
              src={slide_one}
              alt="microsoft datareveal"
              className="img-fluid mx-auto"
              width="50%"
            />
          </div>
          <div>
            <img
              src={slide_two}
              alt="aws datareveal"
              className="img-fluid mx-auto my-auto pt-lg-4 pt-3"
              width="30%"
            />
          </div>
          <div>
            <img
              src={slide_three}
              alt="gcp datareveal"
              className="img-fluid mx-auto"
              width="50%"
            />
          </div>
          <div>
            <img
              src={slide_four}
              alt="os datareveal"
              className="img-fluid mx-auto"
              width="70%"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
