import React from "react";
import { Row, Col } from "react-bootstrap";

var vision_title = {
  fontSize: `2em`,
  fontWeight: `100`,
  color: `#fff`,
};

var sub_title = {
  color: `#fff`,
  textTransform: `capitalize`,
};

function Landing(props) {
  return (
    <div id="landing" className="mt-5">
      <Row style={{ margin: 0, padding: 0 }} className="pt-4">
        <Col
          xxl={5}
          xl={5}
          lg={5}
          md={5}
          className="p-3 ms-lg-5 ms-md-5 my-auto">
          <img
            src={props.landing_img}
            alt={props.landing_img_alt}
            className="img-fluid"
          />
        </Col>
        <Col xxl={6} xl={6} lg={7} md={6} className="my-auto">
          <h2 style={vision_title}>{props.landing_h2}</h2>
          <h3 style={sub_title} className="mb-3">
            {props.landing_h3}
          </h3>
        </Col>
      </Row>
    </div>
  );
}

export default Landing;
