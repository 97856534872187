import React, { Component } from "react";
import Footer from "../component/footer";
import Landing from "../component/landing";

import landing_img from "../component/img/extract-platform/vision/visionlanding.png";

export default class Crypto extends Component {
  render() {
    return (
      <div>
        <Landing
          landing_img={landing_img}
          landing_img_alt="crypto datareveal"
          landing_h2="Datareveal - Crypto&Blockchain"
          landing_h3="Work in progress..."
        />

        <Footer />
      </div>
    );
  }
}
