import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slide_one from "./img/slide1.png";
import slide_two from "./img/slide2.png";
import slide_three from "./img/slide3.png";

export default class Slide extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    };
    return (
      <div className="mt-5 pt-4 pt-lg-0">
        <Slider {...settings}>
          <div>
            <img
              className="w-100"
              src={slide_one}
              alt="First slide datareveal"
            />
          </div>
          <div>
            <img
              className="w-100"
              src={slide_two}
              alt="Second slide datareveal"
            />
          </div>
          <div>
            <img
              className="w-100"
              src={slide_three}
              alt="Third slide datareveal"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
